import {Outlet, Route, Routes} from 'react-router-dom'
import React, {useContext, useEffect, useState} from 'react'
import {ToastContainer} from 'react-toastify';


import 'react-toastify/dist/ReactToastify.min.css';
import '/src/components/forms/forms.css';

// Layouts
import {SinglePageLayout} from './layout/SinglePageLayout/SinglePageLayout';
import {StandardPageLayout} from './layout/StandardPageLayout';

// Home
import Home from './pages';

// Event pages
import Event from './features/events';

// E-commerce pages
import ConfirmationPage from './pages/Confirmation';
import CheckoutPage from './features/ecommerce/checkout/pages/CheckoutPage';

// Other pages
import {TicketTermsConditions} from './pages/TermsConditions';
import {PrivacyPolicy} from './pages/PrivacyPolicy';
import TempHome from './pages/TempHome';


// Regular Imports (Recommended to keep as is due to their likely necessity and small size)
import ScrollToTop from './components/ScrollToTop';
import {AuthContext} from './features/authentication';
import {FileNotFoundPage} from './pages/FileNotfoundPage';
import {PasswordReset} from "./features/authentication/pages/PasswordReset.jsx";
import {Card} from "./features/cards/pages/index.js";
import Confirmation_v2 from "./pages/Confirmation_v2.jsx";
import {EventHome} from "./features/events/pages/index.js";
import {API_URL} from "./js/Helper.js";
import axios from "axios";
import {LinksPage} from "./features/links/pages/index.js";
import PrimaryFeedLayout from "./layout/feeds/PrimaryFeedLayout.jsx";
import {StandardFeedpage} from "./pages/StandardFeedpage.jsx";
import UserHomePage from "./features/user/pages/UserArea/UserHomePage.jsx";
import UserPagesLayout from "./layout/UserPagesLayout.jsx";
import Sandbox from "./features/sandbox/pages/Sandbox.jsx";
import EventBusinessList from "./features/events/pages/EventBusinessList.jsx";
// import Pack from "./features/packs/pages/Pack.jsx";
import CardNew from "./features/cards/pages/CardNew.jsx";
import CardNew2 from "./features/cards/pages/CardNew2.jsx";

import Pack from "./features/packs/pages/Pack.jsx";
import CardLis from "./features/events/pages/CardLis.jsx";

import AffiliateSignup from "./features/events/pages/AffiliateSignup.jsx";



function App() {

  const { user } = useContext(AuthContext);
  const [deploymentId, setDeploymentId] = useState();

    const checkVersion = async () => {
      try {
        const serverResponse = await axios.get(API_URL + '/version');
        const serverData = serverResponse.data;

        const versionResponse = await axios.get('/version.json');
        const clientData = versionResponse.data;

        if (serverData.deploymentId !== clientData.deploymentId && clientData.deploymentId !== 'local') {
          // maybe add an alert here to notify the user of the update
          // If versions or deployment IDs don't match, force reload
          setDeploymentId(clientData.deploymentId)
          alert('A new version of the app is available. Please refresh the page or close down your browser to update the app.')

          window.location.reload();
        }
      } catch (error) {
        console.error('Error checking version:', error);
      }
    };

    if(!deploymentId) {
        checkVersion();
    }



  return (
    <>

      <ScrollToTop />
          <Routes>
            <Route path={'packs/:slug/:contactId'} element={<Pack />}></Route>
            <Route path={'card/:tierId/members'} element={<CardLis />} ></Route>

            <Route path={'card/new'} element={<CardNew2 />} />

            <Route element={<SinglePageLayout/>}>

              <Route path={'card/:slug'} element={<CardNew />} />

              <Route path={'e/:slug/home'} element={<EventHome />} />

              <Route path={'event'}>
                <Route path={''} element={<Event/>} />
                <Route path={':id'} element={<Event/>} />
                <Route path={':slug/home'} element={<EventHome />} />
                <Route path={':slug/home/:id'} element={<EventHome />} />
                <Route path={':slug/affiliate/signup'} element={<AffiliateSignup />} ></Route>
                <Route path={':eventId/guestlists/:guestlistId'} element={<Event/>} />
              </Route>
              <Route path={':slug/events'} element={<EventBusinessList />} />

              <Route path={'sandbox/:component'} element={<Sandbox />}/>

              <Route path='/e/:slug' element={<Event/>}/>

              <Route path={'/password-reset'} element={<PasswordReset />}></Route>
              <Route index element={ user?.id === 427 ? <Home/> : < TempHome />}/>
              <Route path='home' element={<TempHome />}/>

              {/*<Route path='/business/:slug' element={<BusinessSinglePage/>}/>*/}
              {/*<Route path='/reward/:slug' element={<RewardSinglePage/>}/>*/}
              <Route path='/:model' element={<PrimaryFeedLayout/>}></Route>
              {/*<Route path='/:model/:slug/rewards' element={<PrimaryFeedLayout/>}></Route> */}

            </Route>

            <Route path='eventss' element={ <Outlet/> }>
              <Route path=':slug' element={<Event />}></Route>
            </Route>

            <Route>
              <Route path='/confirmation' element={<ConfirmationPage/>}/>
              <Route path='/confirmation/:orderId' element={<ConfirmationPage/>}/>
              <Route path={'confirmation/:type/:orderId'} element={<Confirmation_v2 />} />
            </Route>

            <Route path={'links'}>
              <Route path={':slug'} element={<LinksPage />}></Route>
            </Route>

            <Route path={'user'} element={<UserPagesLayout />} >
              <Route path={'my-account'} element={<UserHomePage />} />
            </Route>

            <Route element={<StandardPageLayout/>}>
              <Route path='checkout/:id' element={<CheckoutPage/> }></Route>
              <Route path='c/:slug' element={<Card />}/>

              <Route path='/privacy-policy' element={<PrivacyPolicy/>}></Route>
              <Route path='/ticket-terms-conditions' element={<TicketTermsConditions/>}></Route>
               <Route path='/:model/:key/:value' element={<StandardFeedpage/>}></Route>
            </Route>


            <Route path='*' element={<FileNotFoundPage/>} />
          </Routes>

        <ToastContainer
        position='bottom-center'
        theme='dark'
        closeOnClick
        limit={3}
        autoClose={2500}
        hideProgressBar={true}
        transition: Bounce
        pauseOnHover={true}
        toastStyle={{
          borderRadius: 'var(--border-radius-default)',
          backgroundColor: 'var(--primary-colour)',
          boxShadow: '10px 10px 10px rgba(0,0,0,0.1)',
          marginBottom: '10px'
        }}
        style={{
          margin: '0 auto',
          zIndex:'100000',
          padding: '20px',
        }}
        />
      </>
  )
}

export default App
