import React from 'react';
import {CheckoutSection} from "../styles/checkoutPage.styles.js";
import LogoComponent from "../../../../components/ui/LogoComponent.jsx";
import {CheckoutBasket, CheckoutOptions} from "./index.js";
import {Divider} from "../../../../components/ui/Divider.jsx";
import {useCheckout} from "../../js/context/CheckoutContext.jsx";
import {DividerWithText} from "../../../../components/ui/LogoWall/DividerWithText.jsx";

const OrderSummarySection = () => {

    const { checkout, total, fees } = useCheckout();

    return (
        <CheckoutSection id='order-summary'>
            {/*<LogoComponent style={{zIndex:5}}  variant={'light'} />*/}
            <CheckoutBasket
                orderLines={checkout?.order_items}
                fees={fees}
                total={total}
            />
            <Divider />
            <CheckoutOptions checkout={checkout} dueToday={total?.due_today} />
        </CheckoutSection>
    );
};

export default OrderSummarySection;