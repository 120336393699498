import React from 'react'
import styled from 'styled-components';
import LogoComponent from './LogoComponent';

const PageContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top:0;
  left:0;
  height: 100vh;
  width: 100vw;
  background-color: var(--default-primary-colour);
`

export const Loading = () => {
  return (
    <>
    <PageContainer>
      <LogoComponent/>
    </PageContainer>
    </>
  )
}
