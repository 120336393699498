import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import {
    useMutation,
  } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { Buffer } from 'buffer';
import { AuthContext } from '../../../authentication';
import axios from 'axios';
import { API_URL, SetLocalStorageJSON, consoleLog, scrollToElement } from '../../../../js/Helper';
import { LoginButton } from '../../../authentication/components/LoginButton';
import { toast } from 'react-toastify';
import { useDrawer } from '../../../../js/hooks/context/DrawerContext';
import { ConvertBase64IdFromParams } from '../../../../js/hooks/useParam';
import { ButtonComponent } from '../../../../components/ui/buttons';
import { Divider } from '../../../../components/ui/Divider';
import { useEcommerce } from '../../js/context/EcommerceContext';
import {useHandleError} from "../../../error-handling/js/hooks/index.js";
import {DividerWithText} from "../../../../components/ui/LogoWall/DividerWithText.jsx";



const Form = styled.form`
  display: ${props => props.display};
  flex-direction: column;
  max-width: 400px;
  box-sizing: border-box;
  gap: 10px;

  @media screen and (min-width: 1920px){
    font-size: 1.5em;
  }
`
const TwoColumn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  gap: 10px;

`


const Select = styled.select`
  flex-grow: 1;
  width: 100%;
  max-width: 450px !important;
  height: 45px;
  padding: 0px 20px;
  border: 1px solid var(--accent-colour);
  outline: none;
  border-radius: var(--border-radius-default);
  background-color: var(--primary-colour);
  text-align: center;
    color: var(--text-colour);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  animation: fadeIn 1s;


`

const Option = styled.option`
`

const Input = styled.input`
    height: 40px;
    min-height: 40px;
`

const Button = styled.button`
  height: 45px;
  width: 100%;
  background-color: ${props => props.alternative == true ? 'rgba(255,255,255,0.025)' : 'var(--accent-colour)'};
  font-size: 0.8rem;
  padding: 0.6rem 10px;
  padding: 0px 20px;
  appearance: none;
  color: white;
  color: ${props => props.alternative == true ? 'rgba(255,255,255,0.5)' : 'white'};
  `

/**
 * TODO: Add auto fill date of birth, gender and
 */

export const CustomerInfoForm = ({checkout}) => {

  
    const { isAuthenticated, user, token, setNewUser, externalLogin } = useContext(AuthContext); 
    const { closeDrawer } = useDrawer();
    const { showErrorToast, showErrorPage } = useHandleError();


    const date = new Date(user && user?.date_of_birth);

    const birthDay = date.getDate();
    const birthMonth = date.getMonth() + 1; // Months are zero-based, so add 1
    const birthYear = date.getFullYear();

    const [firstName, setFirstName] = useState(user ? user.first_name : '');
    const [lastName, setLastName] = useState(user ? user.last_name : '');
    const [email, setEmail] = useState(user ? user.email : '');
    const [confirmEmail, setConfirmEmail] = useState(user ? user.email : '');
    const [mobile, setMobile] = useState(user ? user?.user_contact_details?.mobile : '');
    const [day, setDay] = useState(user && birthDay);
    const [month, setMonth] = useState(user && birthMonth);
    const [year, setYear] = useState(user && birthYear);
    const [gender, setGender] = useState(user ? user.gender : '');
    const [showForm, setShowForm] = useState(isAuthenticated && 'none');
    const [confirmOption, setConfirmOption] = useState('');
    const [confirmed, setConfirmed] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [resMessage, setResMessage] = useState('');
    const { updateConfig } = useEcommerce();

    const CHECKOUT_ID = checkout.id;

    const formRef = useRef();
    const monthRef = useRef();
    const yearRef = useRef();
    
    useEffect(() => {  
      setFirstName(user?.first_name || '');
      setLastName(user?.last_name || '');
      setEmail(user?.email || '');
      setMobile(user?.user_contact_details?.mobile || user?.mobile || '');
      setGender(user?.gender || '');
      setDay(user?.date_of_birth ? birthDay : null);
      setMonth(user?.date_of_birth ? birthMonth : null);
      setYear(user?.date_of_birth ? birthYear : null);
    
    }, [isAuthenticated, user])

    useEffect(() => {
      if(confirmed == true) setConfirmed(false)
    }, [firstName, lastName, email, mobile, gender, day, month, year])

    const updateCheckoutMutation = useMutation(
        ({ checkoutId, formData, token }) => axios.post(`${API_URL}/checkout/${CHECKOUT_ID}/complete`, formData, {
          headers: {
            'Authorization': `${token?.type} ${token?.token}`,
            'Content-Type': 'application/json'
          }
        }), 
        {
          onSuccess: (data) => {
            // handle success

            setIsLoading(false)
            const res = data.data;
            
            if(res.status == 'success') {
              
              if(res.user) SetLocalStorageJSON('user', res.user)
              
              if(res.auth.token) {
                SetLocalStorageJSON('token', res.auth)
                externalLogin(res.auth, res.user)
              }
              
              toast.success(res.message)

              setConfirmed(true)
              updateConfig({isPaymentAllowed: true})
              sessionStorage.setItem('paymentAllowed', true)
              sessionStorage.setItem('enable_payment', true);
              closeDrawer();

              setTimeout(() => {
                  scrollToElement('questions') || scrollToElement('payment')
              }, 500)

              setTimeout(() => {
                setResMessage('')
              }, 5000)

            } else if (res.status == 'error') {
              setResMessage(res.error_message || res.message)
            }
            
          },
          onError: (error) => {
            // handle error
            console.error(error)
            // setResMessage(error.response.data.error_message)
            toast.warning(error.response.data.error_message || error.response.data.message || 'There was an issue processing this request. ' . error?.message);
            setIsLoading(false)
          },
        }
    );


        

    const handleSubmit = async (e) => {
      try {        
        e.preventDefault()
        setIsLoading(true)
    
        const form = formRef.current;
        const formData = new FormData(form);

        if(formData.get('email') !== formData.get('email_confirmation')) {
          toast.warning('Email and Email Confirmation must be identical');
          setIsLoading(false)
          return;
        }
    
        confirmOption && formData.append('method', confirmOption)
        updateCheckoutMutation.mutate({ checkoutId: CHECKOUT_ID, formData, token: token });
      } catch (error) {
        console.error('Error executing updateCheckout mutation', error);
        showErrorToast('We are having troubles processing this request');
      }
    }

    const handleUpdateDetails = () => {
      if(showForm == 'false') {
        setShowForm(true)
        setUpdateButtonText('Confirm Update')
      } else {
      }
    }
    
    const handleDayChange = (e) => {
      const inputDay = e.target.value;
      const numericDay = parseInt(inputDay, 10);
    
      if (inputDay === '' || (numericDay >= 0 && numericDay <= 31 && inputDay.length <= 2)) {
        setDay(inputDay);
    
        if (inputDay.length === 2) {
          // Focus on the next input only when two numbers have been inputted
          monthRef.current.focus();
        }
      }
    };
    

    const handleMonthChange = (e) => {
      const inputMonth = e.target.value;
      const numericMonth = parseInt(inputMonth, 10);
  
      if (inputMonth === '' || (numericMonth >= 0 && numericMonth <= 12 && inputMonth.length <= 2)) {
        setMonth(inputMonth);

        if (inputMonth.length === 2) {
          // Focus on the next input only when two numbers have been inputted
          yearRef.current.focus();
        }
      }
    };

    const handleYearChange = (e) => {
      const inputYear = e.target.value;
      const numericYear = parseInt(inputYear, 10);
  
      if (inputYear === '' || (numericYear >= 1 && numericYear <= 9999 && inputYear.length <= 4)) {
        setYear(inputYear);

        
      }
    };
  

  return (
    <div style={{display:'flex', flexDirection:'column', gap: '20px', padding: '20px', width: '100%'}}>
      {/* <LoginButton login/> */}

      <Form display={'flex'} ref={formRef} onSubmit={handleSubmit} autoComplete='on' id='customer-info-form'>
        <TwoColumn>
          <Input
          onChange={(e) => setFirstName(e.target.value)}
          value={firstName}
          type='text'
          name='first_name'
          placeholder='First Name'
          autoComplete='name'
          aria-autocomplete='fname'
          required autoFocus ></Input>

          <Input
          onChange={(e) => setLastName(e.target.value)}
          value={lastName}
          type='text'
          aria-autocomplete='last_name'
          name='last_name'
          placeholder='Last Name'
          autoComplete='family-name'
          required ></Input>
        </TwoColumn>

        <DividerWithText text={'Contact'} />

        <Input
        onChange={(e) => setEmail(e.target.value)} 
        value={email}
        type='email' 
        name='email' 
        aria-autocomplete='email'
        placeholder='Email' 
        autoComplete='email' 
        required ></Input>

        <Input
        onChange={(e) => setConfirmEmail(e.target.value)} 
        value={confirmEmail}
        type='email' 
        aria-autocomplete='email'
        name='email_confirmation' 
        placeholder='Confirm Email' 
        autoComplete='email'
        required ></Input>

        <Input 
        onChange={(e) => setMobile(e.target.value)}
        value={mobile}
        type='number' 
        aria-autocomplete='mobile'
        name='mobile' 
        placeholder='Mobile' 
        autoComplete='mobile tel'
        minLength={11} 
        required ></Input>

      <DividerWithText text={'Demographics'} />

          <Select name="gender" placeholder='Gender' required>
        <option value={gender} disabled defaultValue={gender ? gender : 'Gender'}>{gender ? gender : 'Gender'}</option>

          <Option value="male">Male</Option>
          <Option value="female">Female</Option>
          <Option value="other">Other</Option>
          <Option value="prefer-not-to-say">Prefer Not Too Say</Option>
        </Select>

        <TwoColumn>

            <Input 
            onChange={handleDayChange}
            value={day}
            type='number'
            inputmode="numberic"
            pattern="[0-9]*"
            name='day' 
            placeholder='DD'
            autoComplete='bday-day'
            max={31}
            min={1}
            required ></Input>

            <Input
            onChange={handleMonthChange} 
            value={month}
            type='number'
            pattern="[0-9]*"
            inputmode="numberic"
            name='month'
            placeholder='MM'
            autoComplete='bday-month'
            ref={monthRef}
            
            max={12}
            maxLength={12}
            min={1}
            required ></Input>

            <Input 
            onChange={handleYearChange}
            value={year}
            type='number'
            pattern="[0-9]*"
            inputmode="numberic"
            name='year'
            placeholder='YYYY'
            autoComplete='bday-year'
            maxLength='4'
            ref={yearRef}

            required ></Input>

   
        </TwoColumn>

        <Divider />

        {
          !isAuthenticated  && (
            <>
            <TwoColumn>
              <ButtonComponent
              variant={'solid'}
              onClick={() => setConfirmOption('register')}
              text={'Confirm & Continue'}
              style={{minWidth:'45%'}}
              isLoading={isLoading && confirmOption == 'register'} 
              />
              <ButtonComponent
              variant={'outlined'}
              onClick={() => setConfirmOption('guest')}
              text={'Create Guest Account'}
              style={{minWidth:'45%', backgroundColor:'rgba(50,50,50,0.9)'}}
              isLoading={isLoading && confirmOption == 'guest'} 
              />
              
            </TwoColumn>
            {/* <p style={{textAlign:'center'}}>Create an account with no extra steps and skip this process on your next visit.</p> */}
            </>
          )
          }


      { isAuthenticated == 'true' || user && (
          <ButtonComponent  variant={'solid'} onClick={() => setConfirmOption('update')}  isLoading={isLoading} text={'Confirm Details'}  />
      ) }
      <p>{resMessage}</p>
      </Form>


    </div>   
  )
}
