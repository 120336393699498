import React from 'react'
import styled from 'styled-components'
import { FeaturedItem } from './FeaturedItem';


const FeaturedSectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 10px;
  width: 100vw;
  height: ${props => props.height || 'fit-content'};
  margin-top: -20px;
  padding: 2rem 2rem;
  overflow-x: auto; // changed from scroll to auto
  scrollbar-width: none; // for Firefox
  -ms-overflow-style: none; // for Internet Explorer and Edge
  scroll-snap-type: x mandatory; // enables x-axis scroll snapping
  box-sizing: border-box;

  &::-webkit-scrollbar { // for Chrome, Safari and Opera 
    display: none;
  }

  @media screen and (min-width: 768px){
    flex-wrap: wrap;
    justify-content: center;
  }
  
  
    @media screen and (min-width: 1920px){
      flex-wrap: wrap;
      justify-content: center;
      max-width: 90vw;
      margin-top: 2em;
    }
  
`;





export const FeaturedSection = (props) => {

    const {data} = props

  return (
    <FeaturedSectionContainer>
      {
        data && data.map((item, index) => {
          return (
            <FeaturedItem key={index} data={item}/>
          )
        })
      }
    </FeaturedSectionContainer>

  )
}
