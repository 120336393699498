import React, { useContext } from 'react'
import styled from 'styled-components'
import { LoginButton } from '../../../authentication/components/LoginButton'
import { ButtonComponent } from '../../../../components/ui/buttons'
import { CustomerInfoForm } from './CustomerInfoForm'
import { useDrawer } from '../../../../js/hooks/context/DrawerContext'
import { angrySteamEmoji, faceScreamEmoji, gearsEmoji, padlockUnlockedEmoji, personSilihouette, smirkingFaceEmoji } from '../../../../js/emojis'
import { AuthContext } from '../../../authentication'
import { UserInfoBox } from '../../../authentication/components/UserInfoBox'
import { scrollToElement, waveEmojo } from '../../../../js/Helper'
import { useEcommerce } from '../../js/context/EcommerceContext'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
    width: 100%;


  @media screen and (min-width: 768px){
    max-width: var(--medium);
  }
`

function CheckoutOptions({ checkout, dueToday }) {

    const { openDrawer } = useDrawer();
    const { isAuthenticated, user, logout } = useContext(AuthContext);
    const { config } = useEcommerce();

    if(config?.paymentStatus === 'complete') return ;

    const scrollToNextElement = () => {
        let element = 'payment-form'

        if(config.hasQuestions === true && config.questionsComplete === true) {
            element = 'payment-form';
        }

        if(config.hasQuestions === true && config.questionsComplete === false) {
            element = 'questions';
        }

        return element;
    }


  return (
    <Container id='checkout-options'>
        { !isAuthenticated  && <ButtonComponent variant={'outlined'} text={`I DONT HAVE AN ACCOUNT` } icon={faceScreamEmoji} onClick={() => openDrawer(<CustomerInfoForm checkout={checkout} />, 'Your Info')} />}
        { !isAuthenticated  && <LoginButton login loginText={`I HAVE AN ACCOUNT`} icon={smirkingFaceEmoji}  /> }
        { isAuthenticated   && <UserInfoBox
                                message={user?.email}
                                icon={personSilihouette}
                                actions={[
                                  {icon: gearsEmoji, onClick: () => openDrawer(<CustomerInfoForm checkout={checkout} />, 'Your Info')},
                                  {icon: padlockUnlockedEmoji, onClick: logout}
                                ]}
                                /> 
        }
        {config?.isPaymentAllowed && isAuthenticated &&
            <ButtonComponent
                variant={'outlined'}
                text={config?.hasQuestions && !config.questionsComplete ? 'Continue' : dueToday > 0 ? 'Proceed To Payment' : 'Continue'}
                style={{
                    height: '45px'
                }}
                onClick={() => scrollToElement(scrollToNextElement())}
            />
        }


    </Container>
  )
}

export default CheckoutOptions