import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import './index.css'
import {HelmetProvider} from 'react-helmet-async'
import {BrowserRouter} from 'react-router-dom'
import {QueryClient, QueryClientProvider} from "@tanstack/react-query"
import {ReactQueryDevtools} from "@tanstack/react-query-devtools"
import * as Sentry from "@sentry/react";
// import BugsnagPluginReact from '@bugsnag/plugin-react'
// import BugsnagPerformance from '@bugsnag/browser-performance'
// import ErrorFallback from './pages/Error.jsx'
import {AuthProvider} from './features/authentication/index.js'
import {ModelsProvider} from './hooks/contexts/ModelsContext.jsx'
import {EcommerceProvider} from './features/ecommerce/js/context/EcommerceContext.jsx'
import {CardProvider} from './features/cards/js/context/CardContext.jsx'
import {DrawerProvider} from './js/hooks/context/DrawerContext.jsx'
import {CheckoutProvider} from './features/ecommerce/js/context/CheckoutContext.jsx'
import {OrderProvider} from './features/ecommerce/orders/hooks/OrderContext.jsx'
import {EventProvider} from './features/events/js/hooks/index.js'
import ErrorFallback from "./pages/Error.jsx";
// import {BUILD_VERSION} from "./js/Helper.js";


const queryClient = new QueryClient();

Sentry.init({
    dsn: "https://99faaea4657885a3900f93c180eee170@o4507242805788672.ingest.de.sentry.io/4507242907435088",
    beforeSend(event, hint) {
        // Check if it is an exception, and if so, show the report dialog
        // if (event.exception && event.event_id) {
        //     Sentry.showReportDialog({eventId: event.event_id});
        // }
        return event;
    },
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        // Sentry.feedbackIntegration({
        //     // Additional SDK configuration goes in here, for example:
        //     colorScheme: 'system',
        // }),
    ],
    environment: import.meta.env.VITE_NODE_ENV,
    // release: import.meta.env.BUILD_VERSION,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});



ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
          <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
            <BrowserRouter>
              <AuthProvider>
                <ModelsProvider>
                  <HelmetProvider>
                      <EventProvider>
                        <EcommerceProvider>
                          <OrderProvider>
                            <CheckoutProvider>
                              <CardProvider>
                                  <DrawerProvider>
                                      <CheckoutProvider>
                                          <App />
                                      </CheckoutProvider>
                                  </DrawerProvider>
                              </CardProvider>
                            </CheckoutProvider>
                          </OrderProvider>
                        </EcommerceProvider>
                      </EventProvider>
                  </HelmetProvider>
                </ModelsProvider>
              </AuthProvider>
            </BrowserRouter>
          </Sentry.ErrorBoundary>
        <ReactQueryDevtools></ReactQueryDevtools>
    </QueryClientProvider>
  </React.StrictMode>,
)
