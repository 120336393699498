import {useContext} from "react";
import {AuthContext} from "../features/authentication";

import {useQuery} from '@tanstack/react-query'
import {makeRequest} from "../services/axios";

export function useApiQuery(path, onSuccess, onError, options) {
  const { header } = useContext(AuthContext);

  if (!path) {
      console.error('Path cannot be null or undefined');
      return;
  }

  const fetchData = async () => {
      return await makeRequest('GET', path, null, header());
  };

  const defaultOptions = {
      cacheTime: 1000 * 60 * 30, // The data will be cached for 30 minutes,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
  };

  const { data, isLoading, error, refetch, isSuccess } = useQuery([path], fetchData, {
      onSuccess,
      onError,
      ...defaultOptions,
      ...options, // Ensure custom options override defaults
  });

  return { data, isLoading, error, refetch, isSuccess };
}
