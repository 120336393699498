import axios from "axios";
import { toast } from "react-toastify";
import { API_URL, getLocalStorageJSON } from "../../../js/Helper";
import {useHandleError} from "../../error-handling/js/hooks/index.js";

export const createMembership = async (data) => {

    const token = getLocalStorageJSON('token')
    const { showErrorToast } = useHandleError();

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization" : `${token?.type} ${token?.token}`
        }
      };
      const res = await axios.post(`${API_URL}/member/create`, data, config);
      return res.data;
    } catch (error) {
      console.error(error);
      showErrorToast(error.message)
      // Handle error appropriately, e.g. show an error message to the user or log it
    } finally {
    }
  };


  export const getMembership = async (data, slug) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
  
      const res = await axios.get(`${API_URL}/card/${slug}`, config);
      return res.data.card;

    } catch (error) {

      console.error(error)
    }

  }
  