import React from 'react';
import styled from "styled-components";
import {ProductClickProvider} from "../hooks/index.js";
import {TicketItem} from "../../../events/components/TicketItem.jsx";
import {useEvent} from "../../../events/js/hooks/index.js";

const TicketListContainer = styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: fit-content;
        min-width: 100%;
        width: 100%;
        max-width: 500px;
        margin-top: 2rem;
        gap: 10px;


        @media screen  and (min-width: 768px) {
          max-height: fit-content;
        }

        @media screen  and (min-width: 1920px) {
          max-width: fit-content;
          max-height: fit-content;
          min-width: fit-content;
        }
    `
export const ProductList = ({ data, style}) => {

    const { event } = useEvent()

    if(!data) return;

    return (
        <TicketListContainer id={'tickets'}>
            {
                // eslint-disable-next-line react/prop-types
                data?.filter(data => data.visibility === 'public').
                map((item, index) => {
                    return (
                        <ProductClickProvider key={index} item={item} event={event}>
                            <TicketItem item={item} />
                        </ProductClickProvider>
                    )
                })
            }
        </TicketListContainer>
    );
};

