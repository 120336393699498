import React from 'react'
import { useEvent } from '../../../js/hooks/EventContext';
import { Paragraph } from '../../../../../components/ui/typography';
import { Section } from '../../../../../layout/components/LayoutSection';
import styled from 'styled-components';
import {angrySteamEmoji} from "../../../../../js/emojis.js";


const ImageWrapper = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 100%;
max-width: 100%;
margin-bottom: 20px;
z-index: 8;

`;


const Image = styled.img`
  width: 100%;
  max-width: 500px;
  height: 200px;
  object-fit: cover;
  margin-top: 20px;
  border-radius: var(--border-radius-default);
  box-shadow: var(--default-shadow);
  background-color: var(--accent-colour);
  
  @media screen and (min-width: 768px) {
    height: 250px;
  }
  
  @media screen and (min-width: 1920px) {
    height: 350px;
    min-width: 40rem;
  }

  `

const SecretLocation = styled.div`
    height: 50px;
`

const VenueSection = ({ venue }) => {

    const { event } = useEvent();

    if(!venue || !event) return;

    const name = event.secret_location ? 'Secret Location' : venue.name;

  return (
    <>
    {
        venue && (
            <Section
            title={!event.secret_location && 'Location'}
            subtitle={`${name}`}
            height="fit-content"
            id="location"
            image={
            event.location?.image && (
                <ImageWrapper style={{marginTop:'12px'}}>

                    {
                        event.secret_location ? (
                            <SecretLocation></SecretLocation>
                        ) : (
                            <Image
                            src={venue?.image.cdnUrl}
                            alt={`Picture of ${name}, the venue for ${event.name}`}
                            title={`Picture of ${name}, the venue for ${event.name}`}
                            loading='lazy'

                            />

                        )
                    }

                </ImageWrapper>
                )
            }
            text={
            <Paragraph style={{textAlign:'center'}}>{venue?.address}</Paragraph>
            }
            >

        </Section>
    )}
    </>
  )
}

export default VenueSection