import React from 'react'
import { useEcommerce } from '../../js/context/EcommerceContext';
import { InfoItemComponent } from '../../../../components/ui/InfoItemComponent';
import { convertToCurrency } from '../../../../js/helpers/helpers';

function BasketFees() {
    const {
        groupedFees,
    } = useEcommerce();


  return (
        groupedFees().length > 0 && (
            <>
            {
                groupedFees().map((fee, index) => {
                    return (
                        <InfoItemComponent key={index}>
                            <p id={fee.name}>{fee.name}</p>
                            <p> </p>
                            <p>{convertToCurrency(fee.amount )}</p>
                        </InfoItemComponent>
                    )
                })
            }
            </>
        )
    )
}

export default BasketFees