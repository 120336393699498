import React from 'react';
import styled from "styled-components";
import {ButtonComponent} from "../../../components/ui/buttons/index.js";
import {DividerWithText} from "../../../components/ui/LogoWall/DividerWithText.jsx";

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100svh;
    padding: 20px;
    gap: 20px;
`

const LinkContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    min-width: 100vw;
    padding: 20px;
`

const LinksPage = () => {
    return (
        <PageContainer>
            <DividerWithText text={'Pick a city'} />
            <LinkContainer>
                <ButtonComponent variant={'solid'} text={'202 Birmingham'}/>
                <ButtonComponent variant={'solid'} text={'202 Manchester'}/>
                <ButtonComponent variant={'solid'} text={'202 London'}/>
            </LinkContainer>
        </PageContainer>
    );
};

export default LinksPage;