import React from 'react'
import styled from 'styled-components'


export const InfoItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 500px;
    padding: 10px;
    min-height: 47px;
    //height: fit-content;
    border-radius: var(--border-radius-default);
    backdrop-filter: blur(10px);
    background-color: rgba(0,0,0,0.1);
    color: var(--text-colour);

    & > p {
      text-align: left;

    }

    & > #property {
      min-width: 180px;
      //width: 180px;
      text-align: left;
      max-width: 180px;
      flex-wrap: wrap;
    }
  
  & > #price {
    text-align: right;
  }
  
    @media screen and (min-width: 785px) {
        /* max-width: 25rem; */
    }

    @media screen and (min-width: 1920px) {
        width: 100%;
        max-width: 30rem;
        height: 2rem;
    }
`

export const InfoItemComponent = ({ children }) => {
  return (
    <InfoItem>{children}</InfoItem>
  )
}
