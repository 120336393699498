import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Section from './Section';
import OrderInfoItem from './OrderInfoItem';
import { useOrder } from '../hooks/OrderContext';
import { Divider } from '../../../../components/ui/Divider';
import {Button} from "@mui/material";

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};


/**
 * 
 */
const OrderDetails = () => {

    const { order } = useOrder()

    if(!order) return;

    

    return (
        <Section style={{gap: '15px'}} >
            <OrderInfoItem primary={'Order Reference'} secondary={order.reference} />
            <OrderInfoItem primary={'Order Status'} secondary={order.status} />
            <OrderInfoItem primary={'Customer'} secondary={order.customer} />
            <OrderInfoItem primary={'Delivered To'} secondary={order.email} />
            {/*<OrderInfoItem primary={'Receipts'} secondary={<ShowHideButton/>} />*/}
        </Section>
    )
}

OrderDetails.propTypes = propTypes;
OrderDetails.defaultProps = defaultProps;
// #endregion

export default OrderDetails;

const ShowHideButton = () => {

    const [toggle, setToggle] = React.useState(false);

    return (
        <button onClick={() => setToggle(!toggle)}>{!toggle ? 'Show' : 'Hide'}</button>
    )
}