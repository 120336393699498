import React from 'react'
import { Section } from '../../../../../layout/components/LayoutSection'
import { FeaturedSection } from '../../../components/FeaturedSection'

const ArtistsSections = ({ artists }) => {

    if(artists.length < 0) return;

  return (
    <Section invert={true} title={artists.length > 0 ? 'FEATURING' : 'DJ LINE UP TO BE ANNOUNCED'}
    id="featured"
    height={artists.length > 0 ? 'fit-content' : '50vh'}
    style={{
        background:'var(--featured-section-bg-colour)',
        padding: '120px 0px'
    }}
    clip={'true'}
    >

    <FeaturedSection data={artists} />

    </Section>  )
}

export default ArtistsSections