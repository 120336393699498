import React from 'react';
import {StandardSection} from "../../../../layout/sections/StandardSection.jsx";
import {ButtonComponent} from "../../../../components/ui/buttons/index.js";
import {useDrawer} from "../../../../js/hooks/context/DrawerContext.jsx";

const AddonProduct = ({ product }) => {

    const { openPreviousDrawer } = useDrawer();

    return (
        <StandardSection>
            { product.description && <div style={{color:'var(--text-colour)', display: 'flex', flexDirection: 'column', gap: '10px', textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: product.description}}></div> }
            <ButtonComponent style={{height: '40px', marginTop: '20px'}} variant={'solid'} onClick={() => openPreviousDrawer()}>Go Back</ButtonComponent>
        </StandardSection>
    );
};

export default AddonProduct;