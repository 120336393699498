import { useState } from "react";
import { calculateBookingFeeFunction, calculateFees } from "./fee";

export const calculateTotal = (orderItems, fees) => {
  console.log('calculateTotal', orderItems)
  // Calculate the sub_total for all items
  const itemsTotal = orderItems.reduce((acc, item) => acc + item.sub_total, 0);
  // Calculate the total amount for all fees
  const feesTotal = fees.reduce((acc, fee) => acc + fee.amount, 0);

  // Sum them up and return
  return itemsTotal + feesTotal;
};


  
export const createOrderItem = (item, quantity) => {

    const itemSubtotal = item.price * quantity;
    const itemFeesAmount = item.booking_fee * quantity;
    const itemTotal = itemSubtotal + itemFeesAmount;

    // this will come from the item object 
    const fees = [
      { name: 'Booking Fee', value: 0.1, valueType: 'percentage', type: 'variable' },
      // ...
    ]
    const fee = calculateFees(quantity, item.price, item.fees)

    return {
      ...item,
      quantity: quantity,
      sub_total: itemSubtotal,
      stripe_amount: itemTotal,
      tax:[],
      addons:[],
    };
};

  

export const updateItemQuantity = (item, newQuantity) => {

  item.fees.forEach(element => {
    element.quantity = newQuantity
  });


  return {
    ...item,
    sub_total: newQuantity * item.price,
    quantity: newQuantity,
    // fees: updatedFees,
  };

};



  

