// PACKAGES
import React, {useEffect, useRef} from 'react'
import styled from 'styled-components';

//COMPONENTS
import {ImageComponent} from '../components/ui/images/ImageComponent';
import {StandardSection} from '../layout/sections/StandardSection';
import {InstructionTitle} from '../components/ui/typography/index.js';
import {updateTheme} from '../features/themes/js/utils';
import {FileNotFoundPage} from './FileNotfoundPage';
import {useOrder} from '../features/ecommerce/orders/hooks/OrderContext';
import {ViewOrderAction} from '../features/ecommerce/orders/components/actions';
import AddToWalletAction from '../features/ecommerce/orders/components/actions/AddToWalletAction';
import {useEcommerce} from '../features/ecommerce/js/context/EcommerceContext';
import {useHandleError} from "../features/error-handling/js/hooks/index.js";
import TopNavigation from "../layout/components/TopNavigation.jsx";
import {Footer} from "../layout/components/Footer.jsx";
import {useApiQuery} from "../hooks/customHooks.js";
import {API_URL} from "../js/Helper.js";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {copyToClipboard} from "../js/utils/browserUtils.js";
import {useEvent} from "../features/events/js/hooks/index.js";

const StickToBottom = styled.section`
  position: sticky;
  bottom: 0;
  left: 0;
  display: grid;
  place-items: center;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  padding: 20px;
  
`;

const PageContainer = styled.section`
    background-size: cover;
    background-position: center;
`

const PageContentContainer = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: fit-content;
    height: 100vh;
    width: 100vw;
    backdrop-filter: blur(5px);

`


const ConfirmationPage = () => {

  const { setOrder, order } = useOrder();
  const { config, emptyBasket } = useEcommerce();
  const { showErrorPage } = useHandleError();
    const toastShownRef = useRef(false);

    // Get the hostname root URL
    const rootUrl = `${window.location.protocol}//${window.location.hostname}`;


  useEffect(() => {
      toast.info('Please allow up to 2 hours for your email to arrive');
  }, [])

    // const searchParams = new URLSearchParams(location.search)
    // const orderId = searchParams.get('orderId');

    const params = useParams();
    const orderId = params.orderId;

    const { data, isLoading, error } = useApiQuery(`${API_URL}/v1/order/${orderId}`, (data) => {
        if (data?.status === 'success') {
            const res = data?.data;
            setOrder(res);

            if (!toastShownRef.current) {
                const booking = res.bookings.length > 0 ? res.bookings[0] : null;

                if(!booking) return

                toast.info('Tap here to copy a link that you can send to your friends that will be joining', {
                    autoClose: false,
                    onClick: () => {
                        copyToClipboard(`${rootUrl}/e/${res.event?.slug}?bid=${booking?.time?.id}-${booking?.id}`)
                    }
                });

                toastShownRef.current = true;
            }
        }
        emptyBasket();
    });


    if(isLoading) return;

    if(error) return showErrorPage(error)

  if(!order) {
    return <FileNotFoundPage /> // todo: this should navigate to home
  }

  updateTheme(order?.event?.theme)


  return (

      <>
        <PageContainer style={{backgroundImage: `url(${order?.event?.image?.cdnUrl})`}}>
            <PageContentContainer>
                {/*<TopNavigation />*/}

                <StandardSection id='logo-section' style={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'center',
                    alignItems:'center',
                }}>
                    {/* <LogoComponent variant={'light'} /> */}
                </StandardSection>

                <StandardSection id='main-section' style={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'center',
                    alignItems:'center',
                    backgroundColor:'transparent',
                    padding:0,
                    height: '80svh',
                }}>

                    <div>

                        <StandardSection id='image-section' style={{
                            width: '100vw',
                            // height: '100svh',
                            display:'flex',
                            flexDirection:'column',
                            justifyContent:'center',
                            alignItems:'center',
                            borderBox:'boxSizing',
                            padding: 0,
                        }}>

                            <InstructionTitle title={'CONFIRMED'} style={{height:'30px', marginBottom: '0px'}} />
                            <ImageComponent src={order?.event?.image?.cdnUrl} width={ innerWidth > 760 ? '350px' : '75%'} />
                            <InstructionTitle title={config?.confirmationMessage || "You're going to " + order?.event?.name + "!!"}
                                              style={{
                                                  height:'fit-content',
                                                  padding:'7.5px 20px',
                                                  width:'200px',
                                                  marginTop:'-30px',
                                                  textTransform:'uppercase',
                                                  fontWeight:'bold',
                                                  lineHeight:'normal',
                                                  zIndex:'2',
                                                  borderRadius:'var(--border-radius-default)'
                                              }} />

                        </StandardSection>

                    </div>

                </StandardSection>

                {
                    order.order_status !== 'requires_authorisation' &&
                    <StickToBottom>
                        <AddToWalletAction />
                        <ViewOrderAction />
                    </StickToBottom>
                }

            </PageContentContainer>

        </PageContainer>
<Footer/>
      </>
    


    
  )
}

export default ConfirmationPage