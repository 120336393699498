import {toast} from "react-toastify";
import * as Sentry from '@sentry/react';
import ErrorFallback from "../../../../pages/Error.jsx";

export const useHandleError = () => {

    const showErrorToast = (error, message) => {
        toast.error(error?.response?.data?.message || error?.message || error);
        reportError(error)
    }

    const showErrorPage = (error, message) => {
        reportError(error)
        return <ErrorFallback error={error} />
    }

    const reportError = (error, message) => {
        Sentry.captureException(error)
        // Bugsnag.notify(new Error(error));
    }



    return {
        showErrorToast,
        showErrorPage,
        reportError,
    }

}