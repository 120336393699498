import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useOrder } from '../../hooks/OrderContext';
import { Order } from '..';
import { ButtonComponent } from '../../../../../components/ui/buttons';
import { useDrawer } from '../../../../../js/hooks/context/DrawerContext';

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

/**
 * 
 */
const ViewOrderAction = () => {

    const { order } = useOrder();
    const { openDrawer } = useDrawer();

    return (
        <ButtonComponent
        variant={'outlined'}
        text={'view order'}
        onClick={() => openDrawer(<Order fresh={true} orderProp={order} />, order.businessName + ' Order')}
        style={{minWidth: '80px', width: '175px', maxWidth: '175px'}}
        />
    )
}

ViewOrderAction.propTypes = propTypes;
ViewOrderAction.defaultProps = defaultProps;
// #endregion

export default ViewOrderAction;