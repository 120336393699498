import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { LoginForm } from './LoginForm'
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
  } from '@tanstack/react-query'
import { AuthContext } from '../AuthContext'
import { IoCloseSharp } from 'react-icons/io5'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { setSessionStorageJSON } from '../../../js/Helper'
import { navigateAndReturn } from '../../../js/auth'
import { useDrawer } from '../../../js/hooks/context/DrawerContext'
import { LoginPage } from '../pages/LoginPage'
import { ButtonComponent } from '../../../components/ui/buttons'
import { smirkingFaceEmoji } from '../../../js/emojis'

const TheLoginButton = styled.button`
    min-height: fit-content;
    padding: 10px 20px;
    font-weight: 800;
    font-size: 0.7em;
    width: 100%;
    height: 50px;
    text-transform: uppercase;
    border: solid 1px var(--accent-colour);
    background-image: var(--accent-colour);
    color: white;
    flex-grow: 1;
    z-index: 100;
    border-radius: var(--border-radius-default);
    backdrop-filter: blur(10px);

    @media screen and (min-width: 1920px){
    }
`

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* max-width: 400px; */
    flex-grow: 1;

`

const Logo = styled.img``

export const LoginButton = ({ returnLocation, login, create, loginText = 'login', style}) => {

    const { isAuthenticated } = useContext(AuthContext)
    const { logout } = useContext(AuthContext)
    const { openDrawer } = useDrawer('bottom');

    const navigate =  useNavigate();

    const loginButton = <ButtonComponent style={style} variant={'solid'} icon={smirkingFaceEmoji} onClick={() => openDrawer(<LoginForm afterLogin={'closeDrawer'} />)}>{loginText}</ButtonComponent>
    const createAccountButton = <TheLoginButton style={style} onClick={() => navigate(navigateAndReturn('afterAuth', '/create-account', returnLocation))}>Create Account</TheLoginButton>

  return (  

    <>


        <ButtonContainer>
    {
        !isAuthenticated && (
            <>
            { create || login ? login ? loginButton : null : loginButton }
            { create || login ? create ? createAccountButton : null : createAccountButton }
            </>
        ) 
    }
    {
    //     isAuthenticated &&
    //    <div style={{display:'flex', gap:'20px', width:'100%'}}>
    //        <TheLoginButton onClick={() => logout()}>Log Out</TheLoginButton> 
    //    </div>
   
    }
        </ButtonContainer>
    </>

  )
}
