import React from 'react'
import styled from 'styled-components'
import { PillContainer } from './styles/pillStyles'



export const LargePill = ({ data, onClick }) => {


  return (
    <PillContainer onClick={onClick}>
      {data?.name}
    </PillContainer>
  )
}
