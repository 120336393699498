// useHandleQuantityChange.js
import { useCallback, useContext } from 'react';
import { calculateBookingFee, calculateBookingFeeFunction } from '../utils/fee';
import { useEcommerce } from '../context/EcommerceContext';
import { updateItemQuantity, calculateTotal } from '../utils';

/**
 * Custom hook to handle quantity changes in an order.
 * @param {Array} orderItems - Array of items in the order.
 * @param {Function} setOrderItems - Function to set the order items.
 * @param {Function} calculateBookingFeeFunction - Function to calculate the booking fee.
 * @param {Function} setBookingFee - Function to set the booking fee.
 * @return {Function} - handleQuantityChange function.
 */
const useHandleQuantityChange = () => {
  const { basket, setOrderItems, basketItems } = useEcommerce();

  return useCallback((e, activeItem) => {
    const newQuantity = Number(e.target.value);

    const updatedItem = updateItemQuantity(activeItem, newQuantity);
    const newOrderItems = basket.orderItems.map(item => {
      if (item.id === activeItem.id) {
        // Update main item
        return updatedItem;
      } else if (item.addons && item.addons.length > 0) {
        // Update addon within the main item's addons array
        const updatedAddons = item.addons.map(addon =>
            addon.id === activeItem.id ? updatedItem : addon
        );
        return { ...item, addons: updatedAddons };
      }
      return item;
    });

    setOrderItems(newOrderItems);
  }, [basketItems(), setOrderItems]);
};


export default useHandleQuantityChange;

