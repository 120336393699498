import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { motion } from "framer-motion"
import { convertToCurrency } from '../../../../js/helpers/helpers';
import BasketItem from './BasketItem';
import {DividerWithText} from "../../../../components/ui/LogoWall/DividerWithText.jsx";

const OrderItemsContainer = styled(motion.div)`

  width: 100%;
  max-height: 'fit-content';
  box-sizing: border-box;
  transition: max-height 2s ease-in-out;
  color: white;

  @media screen and (min-width: 768px){
    max-width: var(--medium);
  }

`
const OrderLinesContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7.5px;
  width: 100%;
  color: var(--text-colour);
`

const TotalContainer = styled(motion.div)`
  /* padding-top: 10px; */

  margin-top: 20px;
`

function CheckoutBasket({orderLines, fees, total}) {

  const [collapsed, setCollapsed] = useState(true);
  const [height, setHeight] = useState(0);

  const handleCollapseToggle = () => {

    if(collapsed) {
      
      const totalContainer = document.getElementById('total-container');
      const totalContainerHeight = totalContainer && totalContainer.scrollHeight
    
      const element = document.getElementById('order-lines-container');
      const elementHeight = element && element.scrollHeight
    
      const orderItemsContainer = document.getElementById('order-items-container')
      const orderItemsContainerHeight = orderItemsContainer &&  orderItemsContainer.scrollHeight;
    
      setHeight(elementHeight  + orderItemsContainerHeight)
    }

    setCollapsed(!collapsed)
  }  
  
  return (
    <OrderItemsContainer
    id='order-items-container'
    collapsed={collapsed}
    >
      {

          <OrderLinesContainer
          id='order-lines-container'
          animate={{
            // display: collapsed ? 'flex' : 'none',
            height: collapsed ? 0 : height,
            opacity: collapsed ? 0 : 1
          }}
          initial={{
            height: 0,
            opacity: 0
          }}
          transition={{ duration: 0.75}}
          >
            <DividerWithText text={'Basket'} />

            <BasketItems collapsed={collapsed} items={orderLines}  />

            { fees.length > 0 && <DividerWithText text={'Fees'} /> }
            {
              fees?.length > 0 && fees.map((fee, index) => {
                return (<BasketItem key={index} collapsed={collapsed} name={fee?.fee_type} value={fee?.amount} />)
              })

            }
          </OrderLinesContainer>

      }

      
      <TotalContainer id='total-container'>
        <BasketItem
        id={'total'}
        name={'Total'}
        info={`Tap to ${collapsed ? 'show' : 'hide'} order summary`}
        value={total?.total}
        style={{
          backgroundColor: collapsed && 'var(--light-colour)',
          color: !collapsed && 'black'
        }}
        onClick={handleCollapseToggle}
        />
      </TotalContainer>
      
      {
        total?.total !== total?.due_today &&
      <TotalContainer id='total-container'>
        <BasketItem
        id={'total'}
        name={'Due Today'}
        // info={`${collapsed ? 'Show' : 'Hide'} order summary`}
        value={total?.due_today}
        style={{
          backgroundColor: collapsed && 'var(--light-colour)',
          color: !collapsed && 'black'
        }}
        onClick={handleCollapseToggle}
        />
      </TotalContainer>
      }
    </OrderItemsContainer> 
  )
}



const BasketItems = ({items, collapsed}) => {
  return (
    items?.length > 0 
    && items.map((item, index) => {
      return (
        <BasketItem
            key={index}
            collapsed={collapsed}
            quantity={item.quantity}
            name={item.item.name}
            value={item.sub_total}
            addons={item.addons}
            category={item.category}
            item={item}
        />
      )
    })  
    
  )
}

export default CheckoutBasket