import {useEcommerce} from "../../js/context/EcommerceContext.jsx";
import React, {useEffect} from "react";
import {Select} from "../../basket/styles/basket.styles.js";
import {DividerWithText} from "../../../../components/ui/LogoWall/DividerWithText.jsx";
import {InstructionTitle, Paragraph} from "../../../../components/ui/typography/index.js";

export const HowManyPeople = ({ product, numberOfPeople, setNumberOfPeople, style }) => {

    if(!product) return;

    const { updateOrderItemValue } = useEcommerce();

    useEffect(() => {
        updateOrderItemValue(product.id, 'quantity', 2);
    }, [])

    const handleNumberOfPeople = (people, product) => {
        const numPeople = Number(people)
        product.options = { ...product.options, numberOfGuests: numPeople }
        product.numberOfGuests = numPeople
        setNumberOfPeople(numPeople)
        updateOrderItemValue(product.id, 'quantity', numPeople);
    }


    const options = [];

    for (let i = 2; i <= 10; i++) {
        options.push(<option label={'Table for ' + i} key={i} value={i}>{i}</option>);
    }

    const customStyle = {
        background: 'var(--colour-light)',
        padding: '10px',
        color: 'black',
        borderRadius: 'var(--border-radius-default)',
        // border: 'solid 1px var(--accent-colour)',
        width: '100%',
        minHeight: '50px',
        height: '40px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        ...style
    }

    return (
        <>
            {/*<DividerWithText text={'Number of Guests'}/>*/}
            <InstructionTitle title={'Number of Guests'} style={{marginBottom: -10}}/>
            <Select
                aria-placeholder={'Table for 4'}
                onChange={(e) => handleNumberOfPeople(e.target.value, product)}
                value={product?.options?.numberOfGuests}
                style={customStyle}
            >
                {options}
            </Select>
        </>

    )
}