import React from 'react';
import {FullPageAbsoluteContainer} from "../../../layout/styles/layoutStyles.js";
import {Product} from "../../ecommerce/products/components/index.js";
import {useApiQuery} from "../../../hooks/customHooks.js";

const Sandbox = () => {

    const { data } = useApiQuery('products/232');

    if(!data) return null;


    return (
        <FullPageAbsoluteContainer style={{height: '100vh', justifyContent: 'center', alignItems: 'center'}}>
            <Product product={data} />
        </FullPageAbsoluteContainer>
    );
};

export default Sandbox;