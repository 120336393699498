import React, {useState} from 'react'
import {IoCardSharp, IoLockClosed} from 'react-icons/io5'
import styled from 'styled-components'
import {angrySteamEmoji} from '../../../js/emojis'
import {useHandleItemClick} from '../../ecommerce/js/hooks'
import {convertToCurrency} from '../../../js/helpers/helpers'
import {useEcommerce} from "../../ecommerce/js/context/EcommerceContext.jsx";


const TicketItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
    box-sizing: border-box;
    height: fit-content;

    @media screen and (min-width: 1920px) {
       min-width: 30rem;
       width: fit-content;
    }

`

const TicketInfoContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 10px;
    gap: 1rem;
    min-height: 50px;
    height: 3.5rem;
    align-items: center;
    border-radius: var(--border-radius-default);
    backdrop-filter: blur(10px);
    box-shadow: 2px 5px 10px rgba(0,0,0,0.1), -7px -3px 20px rgba(0,0,0,0.1);
    cursor: pointer;
    box-sizing: border-box;
    font-size: 0.8em;
    background-color: var(--primary-colour);
    color: var(--text-colour);
  
    &:active {
        transform: scale(0.99);
        box-shadow: 5px 7px 10px rgba(0,0,0,0.2), -7px -7px 15px rgba(0,0,0,0.1);
    }

    @media screen and (min-width: 1920px) {
        padding: 1.5px 2rem;
        height: 3.5rem;
        width: 34rem;
        div {
            font-size: 1.52em;
        }
        justify-content: space-around;
    }
`


const TicketName = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-grow: 1;
    //max-width: 80%;
    line-height: 1.3;
    text-align: center;
    text-decoration: ${props => props.soldOut && 'line-through'};
    text-decoration-color: red;

    

`


const IconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 17%;
    width: 17%;
    max-width: 17%;
    gap: 7px;   
    text-align: center;
`

const VerticalDivider = styled.div `
    height: 100%;
    max-height: 2rem;
    width: 1px;
    opacity: 0.7;
    background: var(--accent-colour);

    @media screen and (min-width: 1920px) {
        margin-left: 50px;
    }
`

const InlineText = styled.p`
    color: var(--accent-colour);
    background: var(--accent-colour);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;`

export const TicketItem = ({ item }) => {


    const [selectedItem, setSelectedItem] = useState(null);
    const [valid, setValid] = useState(false);
    const { time } = useEcommerce()
    const handleItemClick = useHandleItemClick(setSelectedItem, time);

    const remaining = item.remaining;
    const allAvailableItemsInCheckout = remaining === 0 && item.sold_out === false;

  return (
        <TicketItemContainer
            name={`ticket-${item.id}`}
            onClick={() => handleItemClick(item)}
            soldOut={item.sold_out === true || remaining === 0 }
            style={{
                opacity:  allAvailableItemsInCheckout  ? 0.5 : 1
            }}
        >

            <TicketInfoContainer id={`ticket-${item.id}-info`}>
                <IconContainer>{item.price !== 0 ? convertToCurrency(item.price) : '' }</IconContainer>
                <VerticalDivider></VerticalDivider>
                <TicketName soldOut={item.sold_out}>
                    {item.name}
                    {/* <Divider /> */}
                    {/*{ item.id === 57 && <p style={{color: 'var(--error-colour)'}}>Tickets selling quickly!</p>}*/}
                </TicketName>
                <VerticalDivider></VerticalDivider>
                <IconContainer>

                    {
                        item.on_sale == true && item.sold_out == false ? (
                            <>
                            {item.password_protected == true ? <IoLockClosed/> : ''}
                            {item.card_holders_only == true ? <IoCardSharp/> : ''}
                            </>
                        ) : (
                            <>
                            { item.on_sale == true && item.sold_out == false && <p>Not On Sale</p> }
                            { item.sold_out == true ? <p>Sold Out {angrySteamEmoji}</p>  : ''}
                            </>
                        )
                    }

                </IconContainer>
            </TicketInfoContainer>
            

            {/*<TicketDescriptionContainer id={`ticket-${item.id}-description`}>*/}
            {/*   { item.description && <DescriptionRow style={{color: 'var(--text-colour)' }}>{item.description }</DescriptionRow>}*/}
            {/*   { allAvailableItemsInCheckout && <DescriptionRow>All remaining tickets are currently in checkout, try again soo</DescriptionRow>}*/}
            {/*</TicketDescriptionContainer>*/}


        </TicketItemContainer>
  )
}
