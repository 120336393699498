import { LargePill } from "./LargePill";
import { MediumPill } from "./MediumPill";

export const pillComponentMap = {
    LargePill,
    MediumPill
}

export {LargePill};
export {MediumPill}

