// DrawerContext.js
import React, { createContext, useContext, useState } from 'react';
import DrawerPanel from '../../../layout/components/DrawerPanel';

const DrawerContext = createContext();

export const useDrawer = () => {
  return useContext(DrawerContext);
};

export const DrawerProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState(null);
  const [previousDrawer, setPreviousDrawer] = useState(null);
  const [anchor, setAnchor] = useState('bottom');
  const [style, setStyle] = useState({});
  const [title, setTitle] = useState(null);
  // const [previousDrawers, setPreviousDrawers] = useState([])

  const previousContent = () => {
    if(content !== null) {
      setPreviousDrawer({
        content: content,
        title: title,
        style: style,
      })
    }
  }

  const openPreviousDrawer = () => {
    openDrawer(previousDrawer.content, previousDrawer.title, anchor, previousDrawer.style);
  }

  const openDrawer = (newContent, newTitle, newAnchor = null, style = {}) => {
    
    previousContent()

    const autoAnchor = innerWidth > 768 ? 'right' : 'bottom'

    setContent(newContent);
    setAnchor(newAnchor ? newAnchor : anchor ? anchor : autoAnchor)
    setIsOpen(true);
    setStyle(style)
    setTitle(newTitle)
  };

  const closeDrawer = () => {
    setIsOpen(false);
  };

  return (
    <DrawerContext.Provider value={{ 
      openPreviousDrawer,
      closeDrawer,
      openDrawer,
      setTitle,
        previousDrawer,
      style,
      anchor,
      isOpen,
      }}>
      {children}
      <DrawerPanel style={style} anchor={anchor} isOpen={isOpen} toggleDrawer={closeDrawer} title={title}>
        {content}
      </DrawerPanel>
    </DrawerContext.Provider>
  );
};
