import {useEcommerce} from "../../js/context/EcommerceContext.jsx";
import {useHandleRemoveOrderItem} from "../../js/hooks/index.js";
import {useDrawer} from "../../../../js/hooks/context/DrawerContext.jsx";
import {useApiQuery} from "../../../../hooks/customHooks.js";
import {IoAddSharp, IoInformationCircleSharp, IoTrashBinSharp} from "react-icons/io5";
import {DividerWithText} from "../../../../components/ui/LogoWall/DividerWithText.jsx";
import {InstructionTitle, Paragraph} from "../../../../components/ui/typography/index.js";
import {AddonProduct} from "./index.js";
import {convertToCurrency} from "../../../../js/helpers/helpers.js";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-width: 100%;
`

const AddonContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`

const AddonItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    min-width: 100%;
    background-color: ${props => props.isSelected ? 'var(--success-colour)' : 'var(--colour-light)'};
    
    
        // border: solid 1px ;

    border-radius: var(--border-radius-default);
    
    p {
        margin: 0;
    }
`

export const Addons = ({ product, setNumberOfPeople, numberOfPeople }) => {

    const { basketItems, selectedAddons, addAddon, removeAddon } = useEcommerce();
    const { openDrawer } = useDrawer();

    const addons = useApiQuery(`products/${product.id}/addons`);

    if (!addons || !product || addons?.data?.length === 0) return null;


    const iconMap = {
        'add': <IoAddSharp color={'var(--success-colour)'} size={20} />,
        'remove': <IoTrashBinSharp color={'var(--error-colour)'} size={18} />
    };

    product.quantity = numberOfPeople

    return (
        <Container id={product.id + '-addon-container'} >
            {/*<DividerWithText text={'Addons'} style={{margin: '14px 0px',  textTransform: 'uppercase'}} />*/}
            <InstructionTitle title={'Add-ons'} style={{alignSelf: 'center', minWidth: '200px', marginBottom: -10}} />

            <AddonContainer>
                {addons?.data?.map((addon, index) => {
                    const isSelected = selectedAddons.includes(addon) || basketItems().includes(addon);

                    return (
                        <AddonItem
                            id={index + '-addon-item'}
                            isSelected={isSelected}
                            key={index}>

                            <IoInformationCircleSharp
                                color={'var(--info-colour)'}
                                size={20}
                                onClick={() => openDrawer(<AddonProduct product={addon} />, addon.name, 'bottom')}
                            />

                            <Paragraph style={{textAlign: 'left', minWidth: '200px', maxWidth: '200px', color: isSelected ? 'white' : 'black'}} >{addon.name}</Paragraph>
                            <Paragraph style={{color: isSelected ? 'white' : 'black'}}>{convertToCurrency(addon.price)}</Paragraph>

                            {isSelected ? (
                                <span onClick={() => removeAddon(addon, product)}>
                                    {iconMap.remove}
                                </span>
                            ) : (
                                <span onClick={() => addAddon(addon, product)}>
                                    {iconMap.add}
                                </span>
                            )}

                        </AddonItem>
                    );
                })}
            </AddonContainer>
        </Container>
    );
};
