import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { AddToAppleWallet } from '../../../../../components/ui/AddToAppleWallet';
import { useOrder } from '../../hooks/OrderContext';

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

/**
 * 
 */
const AddToWalletAction = () => {

    const { order } = useOrder();

    if(!order) return;

    if(order.digitalWalletEnabled) return;

    if(order.status === 'Payment Failed' || order.status === 'Processing Failed' || order.status === 'Refunded') return;

    return (
        <AddToAppleWallet type='order' id={order.reference}  />
    )
}

AddToWalletAction.propTypes = propTypes;
AddToWalletAction.defaultProps = defaultProps;
// #endregion

export default AddToWalletAction;