import { createContext, useContext, useState } from "react";

const EventContext = createContext();

export const EventProvider = ({ children }) => {

    const [event, setEvent] = useState();

    return (
        <EventContext.Provider value={{
            setEvent,
            event,
        }}>
            {children}
        </EventContext.Provider>
    )
}

export const useEvent = () => {
    const context = useContext(EventContext);

    if(!context) {
        throw new Error('EventContext must be used within a EventProvider')
    }

    return context;
}