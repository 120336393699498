import React from 'react';
import StripePaymentForm from "../../services/stripe/components/StripePaymentForm.jsx";
import {CheckoutSection} from "../styles/checkoutPage.styles.js";
import {useEcommerce} from "../../js/context/EcommerceContext.jsx";
import {useCheckout} from "../../js/context/CheckoutContext.jsx";
import {useEvent} from "../../../events/js/hooks/index.js";

const PaymentSection = () => {

    const { config } = useEcommerce();
    const { total, stripe } = useCheckout();
    const { event } = useEvent();

    // if(config?.isPaymentAllowed !== true) return;


    return (
        <CheckoutSection
            id='payment'
            style={{
                clipPath: !config?.hasQuestions && 'polygon(0 0, 100% 20%, 100% 100%, 0 80%)',
                background: !config?.hasQuestions && 'rgba(0,0,0,0.1)',
            }}
        >

            <StripePaymentForm
                payment_intent={stripe?.intent}
                total={total?.due_today}
                theme={event?.theme}
            />

        </CheckoutSection>
    );
};

export default PaymentSection;