import React from 'react'
import { pillComponentMap } from '../../components/ui/Pills';
import { StandardFlexLayout } from '../styles/flex';
import { defaultTheme } from '../../js/styles/defaultTheme';
import {useDrawer} from "../../js/hooks/context/DrawerContext.jsx";
import {StandardSection} from "../sections/StandardSection.jsx";
import {Paragraph} from "../../components/ui/typography/index.js";

const TagListLayout = ({ data, style, component, onClick, data_model}) => {

  const { openDrawer } = useDrawer();

  return (
    <StandardFlexLayout theme={defaultTheme} style={{padding:'20px 0px'}}>
        {data &&
            data.map((item, index) => {

                const PillComponent = pillComponentMap[component];

                return (
                    <PillComponent
                        key={index}
                        data={item}
                        data_model={data_model && data_model}
                        style={style && style}
                        onClick={()  =>
                              openDrawer(<StandardSection><Paragraph>{item?.name}</Paragraph></StandardSection>)
                        }
                    />
            );
        })}
    </StandardFlexLayout>
  )
}

export default TagListLayout