import React from 'react'
import { Section } from '../../../../../layout/components/LayoutSection'
import { ImageComponent } from '../../../../../components/ui/images/ImageComponent'
import { useEvent } from '../../../js/hooks/EventContext'
import { ImpactTitle } from '../../../../../components/ui/typography'
import { Divider } from '../../../../../components/ui/Divider'

const DescriptionSection = () => {

    const { event } = useEvent();

    if(!event) return;

  return (
    <Section height="fit-content" id="information" >

        <ImpactTitle  subHeader={'about'} main={event?.name}/>
        <Divider style={{marginTop: '20px', marginBottom: '10px'}} />
        <div style={{
            position: 'relative',
            zIndex: 1,
            padding: '10px 15px',
            borderRadius: '10px',
            backdropFilter: 'blur(10px)',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            textAlign:'center',
            color:'white'


        }} 
        dangerouslySetInnerHTML={{ __html: event?.description }} />

    </Section>
  )
}

export default DescriptionSection