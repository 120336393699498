import React, {useEffect, useState} from 'react'
import {useCard} from '../js'
import {useDrawer} from '../../../js/hooks/context/DrawerContext'
import {StandardSection} from '../../../layout/sections/StandardSection'
import CardPageContentComponent from '../components/CardPageContentComponent'
import {updateTheme} from "../../themes/js/utils.js";
import {useEvent} from "../../events/js/hooks/index.js";


const PrimaryCardPage = ({ card, activeTier, feature }) => {

    const { selectedTier } = useCard();
    const { isOpen } = useDrawer();
    const { event } = useEvent()
    const [isLoading, setIsLoading ] = useState(sessionStorage.getItem('isLoading'));


    useEffect(() => {
        if(isOpen === false) {
            if(event) updateTheme(event.theme)
        }
    }, [isOpen]);

     return (
        <>
            <StandardSection style={{display:'flex', justifyContent:'center', alignItems:'center', height:'fit-content', padding: '0px'}}>
                <CardPageContentComponent card={card} selectedTier={activeTier} feature={feature}/>
                {/* <LoyaltyCard card={card} tier={selectedTier ? selectedTier : activeTier} /> */}
                {/* <ButtonComponent
                text={'BECOME A MEMBER'}
                showBack={false}
                onClick={() => openDrawer('', innerWidth > 768 ? 'right' : 'bottom', {
                    maxWidth:'40px'
                })}
                isLoading={isLoading}
                colour={'var(--accent-colour)'}
                style={{
                    width:'100%',
                    maxWidth:'100%'
            }}
        /> */}
            </StandardSection>
        </>

  )
  
}

export default PrimaryCardPage