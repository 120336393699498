import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { extractColorCode, extractColorCodeFromCssVariable } from '../../../themes/js/utils';

// #region constants

// #endregion

// #region styled-components
const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0;
    
    `

const Primary = styled.p`
    flex: 1;
    font-weight: bold;
    font-size: 1.1rem;
    margin: 0;
    padding: 0;
    // color:  ${extractColorCodeFromCssVariable('--accent-colour') || 'white'};
    background: var(--accent-colour);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    `
const Secondary = styled.p`
    flex: 1;
    color: var(--text-colour);
`

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};


/**
 * 
 */
const OrderInfoItem = ({ primary, secondary, right}) => {

    if(!primary || !secondary) return

    return (
        <Container right={right}>
            <Primary>{primary}</Primary>
            <Secondary>{secondary}</Secondary>
        </Container>
    );
}

OrderInfoItem.propTypes = propTypes;
OrderInfoItem.defaultProps = defaultProps;
// #endregion

export default OrderInfoItem;