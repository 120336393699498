import React from 'react'

import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 25px;
    width: 100%;
`

const DividerComponent = styled.div`

  height: 1px;
  max-height: 2px;
  width: 20%;
  max-width: 100px;
  background-color: rgba(255,255,255,0.15);
  margin-top: 3px;
  margin-bottom: 3px;
    
`

export const DividerWithText = ({style, text}) => {
  return (
      <Container style={style}>
        <DividerComponent/>
        <p style={{color:'var(--text-colour)'}}>{text}</p>
        <DividerComponent/>
      </Container>
  )
}
