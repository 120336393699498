import React from 'react';
import {useForm} from "react-hook-form";
import {inputComponentMap} from "../../../components/forms/js/inputMap.js";
import {Form} from "../../../components/forms/formStyles.js";
import {ButtonComponent} from "../../../components/ui/buttons/index.js";
import {StandardSection} from "../../../layout/sections/StandardSection.jsx";
import styled from "styled-components";
import {InstructionTitle, Paragraph} from "../../../components/ui/typography/index.js";
import {useMutation} from "@tanstack/react-query";
import {makeRequest} from "../../../services/axios.js";
import {toast} from "react-toastify";
import {useHandleError} from "../../error-handling/js/hooks/index.js";
import {DividerWithText} from "../../../components/ui/LogoWall/DividerWithText.jsx";

const FormContainer = styled(Form)`
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 20px 0px;
    gap: 5px !important;
`

const Label = styled.label`
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: var(--border-radius-default);
    border-top-right-radius: var(--border-radius-default);
    min-width: 60%;
    max-width: 80%;
    height: fit-content;
    //transform: rotate(-2deg);
    
    text-align: center;
    font-weight: 600;
    padding: 0px 20px;
    margin-bottom: -1px;
    z-index: 1;
    background: var(--default-accent-colour);
    box-shadow: inset 10px 10px 10px rgba(0,0,0,0.15);
    color:var(--text-colour);
`


const PackForm = ({name, inputs, submitButton, onSuccess, referrer = null, endpointUrl = 'packs/1/response'}) => {

    const { register,reset, handleSubmit, formState: { errors } } = useForm();
    const {showErrorToast} = useHandleError()

    const mutation = useMutation(
        (data) => {
            // Call to makeRequest function to send the form data
            return makeRequest('POST', endpointUrl, data, {}, "multipart/form-data");
        },
        {
            // onError and onSuccess functions for mutation
            onError: (error, variables, context) => {
                showErrorToast(error, 'Error creating pack')
            },

            onSuccess: (data, variables, context) => {
                const toastType = {
                    success: toast.success,
                    info: toast.info,
                    error: toast.error,
                    warning: toast.warning,
                }[data.status] || toast.info; // Default to info if status is not recognized

                toastType(data.message ?? data.status); // Call the appropriate toast function

                onSuccess && onSuccess(data, variables, context);
                // reset();
            },

        },
    );

    const onSubmit = (submittedData) => {
        let formData = new FormData();

        Object.entries(submittedData).forEach(([key, value]) => {
            formData.append(key, value);
        });

        formData.append('pack_id', 1);
        formData.append('referrer', referrer)

        const data = Object.fromEntries(formData)
        mutation.mutate(data)
    }

    return (
        <FormContainer id={name + ' form'} onSubmit={handleSubmit(onSubmit)}>
            {inputs?.map((input, index) => {

                const name = input?.name ?? `input-${index}`;
                const disabled = input?.disabled ?? false;

                return (
                    <div key={index}  style={{width: '100%', minHeight: 'fit-content', padding: '0 20px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                        { input.type !== 'hidden' && <Label text={input.label} htmlFor={name}>{input?.label}</Label> }
                        {input.type  &&
                        <input
                            style={{zIndex: 2, marginBottom: input.type === 'hidden' ? '0px' : '5px'}}
                            {...register(name)}

                            name={name}
                            placeholder={input.placeholder ?? name}
                            disabled={disabled}
                            value={input?.value}
                            {...register(name)}
                            type={input?.type}
                            // onChange={'onChange'}
                            autoCorrect={input?.autoCorrect}
                            required={input?.required}
                        />
                        }
                    </div>

                )

            })}

            {inputs.find((input) => input.name === 'questions') && (
                <>
                    <Label style={{marginBottom: '-10px'}} htmlFor={name}>Questions</Label>
                    <textarea {...register('questions')}></textarea>
                </>

        )}


            <Paragraph style={{fontSize: '0.7rem', padding: '20px', paddingBottom: '0px'}}>By submitting this form you are accepting our <a
                rel={'noreferrer'} href={'/ticket-terms-conditions'} target={'_blank'}> <strong>terms and
                conditions</strong></a> and <a rel={'noreferrer'} href={'/privacy-policy'} target={'_blank'}><strong>privacy policy</strong></a> .</Paragraph>

            <ButtonComponent
                variant={submitButton?.variant ?? 'solid'}
                text={submitButton?.text ?? 'Submit'}
                isLoading={mutation.isLoading}
            />

        </FormContainer>
    )
}

export default PackForm;
