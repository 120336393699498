import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import React from 'react'
import { IoArrowDownSharp } from 'react-icons/io5'
import styled from 'styled-components'
import { StandardSection } from '../../../layout/sections/StandardSection'



const Accordian = ({ data }) => {

    if(!data || data.length === 0) return;

  return (
    <StandardSection>

        {
            data.map((item, index) => {
                return (
                    <Accordion style={{minWidth: '100%'}}>
                        <AccordionSummary
                        expandIcon={<IoArrowDownSharp />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        >
                        {item.placeholder}
                        </AccordionSummary>

                        <AccordionDetails >
                            {item.description}
                        </AccordionDetails>
                </Accordion>
                )
            })

        }
  </StandardSection>
  )
}

export default Accordian