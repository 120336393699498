import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeRequest } from '../../../../services/axios';
import { AuthContext } from '../../../authentication';
import { useEcommerce } from '../context/EcommerceContext';
import { useDrawer } from '../../../../js/hooks/context/DrawerContext';
import { Buffer } from 'buffer';
import { paths } from '../../../../js/helpers/paths';
import {useHandleError} from "../../../error-handling/js/hooks/index.js";
import {getBookingId} from "../../../bookings/js/utils/storage.js";
import {useAffiliate} from "../../../.feature_folder/js/hooks/useAffiliate.jsx";

/**
 * Custom hook to handle the checkout process.
 *
 *
 * @return {Object} - { handleClickCheckout, checkoutLoading, checkoutMessage }
 */
const useHandleClickCheckout = () => {
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [checkoutMessage, setCheckoutMessage] = useState(null);


  const navigate = useNavigate();
  const { header } = useContext(AuthContext)
  const { closeDrawer } = useDrawer('top');
  const { showErrorToast } = useHandleError();
  const {getAffiliateBySlug} = useAffiliate();


  const {
    basket,
  } = useEcommerce();

  const handleClickCheckout = async (event, business) => {

    const affiliates = getAffiliateBySlug(event?.slug);

    try {
      setCheckoutLoading(true);

      const data = {
        event_id: event?.id,
        business_id: business?.id,
        order_items: basket?.orderItems,
        fees: basket?.orderFees,
        bookingId: getBookingId().bookingId,
        affiliates: affiliates
      };

      const response = await makeRequest('POST', 'checkout', data, header(), null);

      // setPaymentIntent(response.stripe)
      // setOrderItems(response.orderItems)
      // setBasket(response.basket)

      console.log(response)

      if(response?.status !== 'success') {
        showErrorToast(response.data.message)
      }

      setCheckoutLoading(false)

      closeDrawer();

      const id = response.basket.id.toString();
      const base64Id = Buffer.from(id).toString('base64');

      if (response.info != null) sessionStorage.setItem('checkoutMessage', response.info);

      navigate(`/${paths['checkout']}/${base64Id}`);
    } catch (error) {
      showErrorToast(error.response.data.error_message || error.message)
      console.error('Error:', error);
      setCheckoutLoading(false)
      setCheckoutMessage(error.message);
    } 
    
  };

  return {
    handleClickCheckout,
    checkoutLoading,
    checkoutMessage
  };
};


export default useHandleClickCheckout