
// External packages 
import React, { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'


// Components
import { Loading } from '../../../components/ui/Loading'
import { FileNotFoundPage } from '../../../pages/FileNotfoundPage'

import { scrollToElement, setSessionStorageJSON } from '../../../js/Helper'
import { useApiQuery } from '../../../hooks/customHooks';
import {filterPartnersByRoles, filterProductsByCategory} from '../js/utils';
import PrimaryEventPage  from './PrimaryEventPage';
import { useEvent } from '../js/hooks/index.js'
import useUpdateTheme from '../../themes/js/hooks/useUpdateTheme'
import ErrorFallback from "../../../pages/Error.jsx";
import {useHandleError} from "../../error-handling/js/hooks/index.js";
import {useDrawer} from "../../../js/hooks/context/DrawerContext.jsx";
import {updateTheme} from "../../themes/js/utils.js";
import {setBookingId} from "../../bookings/js/utils/storage.js";
import {PRODUCT_TABLE, PRODUCT_TICKET} from "../js/constants.js";
import {useAffiliate} from "../../.feature_folder/js/hooks/useAffiliate.jsx";
import {LOCAL_STORAGE_AFFILIATE} from "../../.feature_folder/js/constants.js";

export const Event = () => {

  // hooks
  const { setEvent } = useEvent();
  const { checkIfAffiliateExists, checkIfReferred, storeAffiliate, getAffiliateBySlug} = useAffiliate()
  const { showErrorPage, reportError} = useHandleError();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const {isOpen} = useDrawer();

  const slug = params.slug;
  const searchParams = new URLSearchParams(location.search);
  const section = searchParams.get('section')

  useEffect(() => {
    setBookingId(slug)

    /**
     * TODO - Could extract this into a separate function
     */
        // Check if the 'referral' parameter is set
    const affiliateId = searchParams.get(LOCAL_STORAGE_AFFILIATE);
console.log(affiliateId)
    if(!checkIfAffiliateExists(affiliateId, slug) && affiliateId) {

      const affiliate = {
        id: affiliateId,
        type: 'event',
        slug: slug
      }

      storeAffiliate(affiliate)
    }

  }, [])









      // const storedRefferalCodes = localStorage.getItem('referral') ?? []

      // localStorage.setItem('referral', JSON.stringify(storedRefferalCodes.push(referral)))

  const referralParam = searchParams.has(LOCAL_STORAGE_AFFILIATE) ? `referral=${searchParams.get(LOCAL_STORAGE_AFFILIATE)}` : '';

  const relations = '?with=location,business,theme,partners.image,products.dependencies,fileLinks.file,eventDates';

  let path = `event/${slug}${relations}&${referralParam}`

  const { data, isLoading, isError } = useApiQuery(path, 
    data => {
      setSessionStorageJSON('event', data.event)
      if(data.status === 'error') navigate('/404')
    },
    error => console.error(error.message),
    );


    useEffect(() => {
      setEvent(data?.data)
    }, [data?.data])

  useEffect(() => {
    if(isOpen === false) {
      updateTheme(event?.theme)
    }
  }, [isOpen])

    let event;
    let hasDates;
    let organiser;
    let business;
    let venue;
    let tickets;
    let tables;
    let djs_artists;
    let sponsors;

  try {
    event = data?.data;

    useUpdateTheme(event?.theme)

    if(!event) { return <Loading /> }

    hasDates = event.eventDates && true;
    organiser = event.business
    business = event.business
    venue = event.location
    tickets = filterProductsByCategory(event.products, PRODUCT_TICKET);
    tables = filterProductsByCategory(event.products, PRODUCT_TABLE);
    djs_artists = filterPartnersByRoles(event.partners, ['dj', 'artist']);
    sponsors = filterPartnersByRoles(event.partners, ['sponsor']);
  } catch (e) {
    console.error('Error assigning variables in event page', e);
    reportError(e.message)
    return showErrorPage(e, e.message)

  }

  if(isLoading || !event) return <Loading/>
  if(isError) return <FileNotFoundPage/>


  if(section) {
    scrollToElement(section)
  }

  return (
    <>

    <Helmet prioritizeSeoTags>
      <title>{`${event?.business?.name} | ${event?.name} ~ Nocturnal Lifestyle`}</title>
      <meta name='description' content={event?.short_description}/>
      <meta property="og:title" content={`${event.business.name} | ${event.name} ~ Nocturnal Lifestyle`}/>
      <meta property="og:description" content={event.short_description}/>
      <meta property="og:url" content={window.location.hostname}/>
      <meta property='og:image' content={event.image}/>
      <meta property='og:type' content={'Website'}/>
      <link rel="canonical" href={window.location.hostname} />
    </Helmet>
    
    {
      event &&
      <PrimaryEventPage
      event={event}
      tickets={tickets}
      business={business}
      organiser={organiser}
      venue={venue}
      djs_artists={djs_artists}
      sponsors={sponsors}
      tables={tables}
      hasDates={hasDates}
      />
    }
    </>
  )
}


