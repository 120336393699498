import React from 'react';
import styled from 'styled-components';
import PropTypes, { object } from 'prop-types';
import { AddToAppleWallet } from '../../../../components/ui/AddToAppleWallet';
import { ButtonComponent } from '../../../../components/ui/buttons';
import { getSessionStorage } from '../../../../js/Helper';
import OrdersPage from '../../../user/pages/OrdersPage/OrdersPage';
import { useDrawer } from '../../../../js/hooks/context/DrawerContext';
import { Order } from '.';
import { useOrder } from '../hooks/OrderContext';
import ViewOrderAction from './actions/ViewOrderAction';
import AddToWalletAction from './actions/AddToWalletAction';
import Section from './Section';
import RefreshAction from './actions/RefreshAction';
import { DownloadFilesAction } from './actions';

// #region constants

// #endregion

// #region styled-components
const ActionsContainer = styled.section`
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  
  *:only-child {
    grid-column: 1 / -1; /* Stretch the only child across all columns */
  }

`
// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
    order: object
};

const defaultProps = {};

/**
 * 
 */


const NextOptions = styled.section`

  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  width: 100%;

`
const OrderActions = ({ preset }) => {

    return (
        <ActionsContainer>
            <AddToWalletAction />
            {/*<DownloadFilesAction />*/}
            {/*<RefreshAction text={'view All Orders'} />*/}
        </ActionsContainer>

    );
}

OrderActions.propTypes = propTypes;
OrderActions.defaultProps = defaultProps;
// #endregion

export default OrderActions;