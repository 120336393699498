import React from 'react';
import styled from 'styled-components';

const PageContainer = styled.div`
  display: grid;
  height: 100dvh;
  padding: 0px 20px;
  place-items: center;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  max-width: 400px;
  padding: 20px;
  border-radius: 20px;
  color: var(--text-colour);
  //box-shadow: 7px 5px whitesmoke;
  //background-color: var(--accent-colour);
  gap: -20px;
`



const Header = styled.h2`
  font-size: large;
  margin-bottom: -10px;
`

const Text = styled.p`
  margin: 10px 0px;
`

const Link = styled.button`
  padding: 10px;
  width: 150px;
  margin-top: 15px;
  font-size: 1em;
  font-weight: bold;
  border: solid 1px white;
  background-color: transparent;
  color: white;
`

function ErrorFallback({ error, componentStack, FallbackProps }) {

  return (
    <PageContainer>
      <Container>
        <div>
          {/*<LogoComponent style={{zIndex:5}}  variant={'light'} />*/}

        <Header>Oops, something went wrong, Looks like theres been an error! &#128561;</Header>
        <Text>Tap the icon in the bottom corner to report a bug.</Text>
        </div>
        <Text>It is possible you have landed on a old version. Scroll to bottom and check for version 0.9 or above. Open in Safari/Chroe for latest version.</Text>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
          <Text>It's probably a quick fix! If reloading the page does not work please close the browser and try again.</Text>
          <Text>If you are still experiencing problems please contact us via instagram @nocturnallifestyle.</Text>
          {/*<a  target='_blank' href='https://nctrl.atlassian.net/servicedesk/customer/portal/2'>*/}
          {/*  <Link>Report Bug</Link>*/}
          {/*</a>*/}
      </Container>
    </PageContainer>
  );
}

export default ErrorFallback;
